<script>
import {deepCopy, deepMerge} from '@/util/objects';

export default {
    data() {
        this.defaultProps = {
            children: 'subNodes',
            label: 'name'
        };
        this.isUpdate = false;
        return {
            filterText: '',
            nextShow: false,
            expanded: [],
            treeData: [],
            form: {}
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    mounted() {
        this.defaultToAdd();
        this.defaultRefreshTree();
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name? data.name.indexOf(value) !== -1 : data.jyzbmc.indexOf(value) !== -1;
        },
        defaultRefreshTree() {
            //默认树刷新
            return this.treeService().then((response) => {
                this.treeData = response.data;
                this.$nextTick(() => {
                    this.$refs.tree.setCurrentKey(this.form.id);
                })
            })
        },
        defaultNodeClick(data) {
            //展示编辑等按钮
            this.nextShow = true;
            this.isUpdate = true;
            this.form = deepMerge(this.defaultModel, data)
        },
        defaultToAdd() {
            this.nextShow = false;
            this.isUpdate = false;
            this.form = deepCopy(this.defaultModel);
        },
        defaultNext() {
            let parentId = this.form.id;
            let level = this.form.level + 1;
            this.defaultToAdd();
            this.form.parentId = parentId;
            this.form.level = level;
        },
        defaultDel() {
            return this.delService(this.form.id).then((response) => {
                this.$message.info(response.msg + "共删除【" + response.data + "】条数据！");
                this.defaultToAdd();
            })
        },
        async defaultSave() {
            await this.$refs.form.validate();
            let api = this.isUpdate ? this.updateService : this.addService;
            const response = await api(this.form);//执行保存方法
            //打印提示信息
            this.$message.success(response.msg);
            this.nextShow = true;
            this.form.id = response.data;
            this.expanded = [this.form.id];
            this.isUpdate = true;
            return response;
        }
    }
}
</script>
